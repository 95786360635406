import { index } from 'd3';
import React ,{useState,useEffect} from 'react';
import { ToggleButton } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();



// const Addtask=({addtask})=>{
//     const [value,updatevalue]=useState("");
//     const handleSubmit = e=>{
//         e.preventDefault();
//         if(value !== ""){
//         addtask(value);
//         updatevalue("");
    
//         }
//     }
//     return(
//     <form onSubmit={handleSubmit}>
//        <input type="text" value={value} onChange={(e)=>updatevalue(e.target.value)} />
//        <button type="submit" >Add</button>
//     </form>
//     )    
//     }
    
//     const Todo = () =>{
//         const addtask = text => {updatestate([...task,{text}]);console.log(task); };
//         const [value,updatevalue1]=useState("");
    
//         const [task,updatestate]= useState([
//             {
//     text:'task1',
//         },
//         {
//         text:'task2',
      
//         },
//         {
//         text:'task3',
//         }
//     ]);
    
     
//     const toggleButton = index =>{
//        const newitem=[...task];
//     if(newitem[index].isCompleted){
//     newitem[index].isCompleted=false;
//     }
//     else{
//     newitem[index].isCompleted=true;
//     }
//     console.log(newitem);
//     updatestate(newitem);
//     }
//     const updatesubmit=(e)=>{
//     const newitem=[...task];
//     updatestate(value);
//     updatevalue1(""); 
        
//     }
//     const removeTask = index =>{
//     const newitem = [...task];
//     newitem.splice(index,1);
//     updatestate(newitem);
//     }
//     return(
//        <div className="list-of-todo">
//            {task.map((task,index) => (
//             <div className="task-status" >
//             <span onClick={() => toggleButton(index)} className={(task.isCompleted) ? "completed-task":"task-name"}>
//                {task.text} 
//            </span>
//            <button onClick={() => removeTask(index)}>remove</button>
//             </div>
            
//            ))}
//             <input type="text" onChange={(e)=>updatevalue1(e.target.value)} />
//        <button type="button" onClick={()=>updatesubmit()}>Add</button>
       
//            <Addtask addtask={addtask} />
//        </div> 
//     )
    
//     };