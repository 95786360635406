import React from 'react';
import { Link } from "react-router-dom";

export default function Footer(props) {
    return (
        <>
            <footer className="footer-area">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-sm-4 col-md-4 col-xl-3">
                            <div className="single-footer-widget footer_1">
                                <a href="index-2.html" style={{ width: '35%' }}> <img src="img/logo.png" alt="" style={{ width: '75%' }} /> </a>
                                <p>FlexCV is one of the best online resume builders that allows creating highly customizable  CVs in minutes. </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-xl-7">
                            <div className="single-footer-widget footer_2">
                                <h4><center>About Us</center></h4>
                                <div className="contact_info">
                                    <p>Our passion for technology — makes us happy. We truly believe in the transformative power of a good CV, its presentation and its ability to simplify expressing one's skill set, elevate chances, and engage potential employers. We're excited to present a simple and easy way for your employer to know about you, that will get something you deserve, a "Career".</p>
                                    <div className="social_icon">
                                        <a href="https://www.facebook.com/sharer/sharer.php?u=http://flexcvpro.com/" > <i className="ti-facebook"></i> </a>
                                        <a href="https://twitter.com/home?status=http://flexcvpro.com/ I have used flexcvpro.com and found that it is super easy, quick and customizable to create a crisp resume. You can also try to create your resume. Please try it at: https://flexcvpro.com" > <i className="ti-twitter-alt"></i> </a>
                                        <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://flexcvpro.com/&title=&summary=I have used flexcvpro.com and found that it is super easy, quick and customizable to create a crisp resume. You can also try to create your resume. Please try it at: https://flexcvpro.com&source=" > <i className="ti-linkedin"></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyright_part_text text-center">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <p className="footer-text m-0"><span style={{ float: 'left', marginLeft: '1em' }}>Copyright &copy; All rights reserved  by <a href="https://gravitykey.com/" target="_blank" rel="noopener noreferrer">Gravitykey </a> </span>
                                            <span style={{ float: 'right', marginRight: '1em' }}>      <Link to="/Termsofservice" id="term">Terms of Service</Link> | <Link to="/Privacy" id="privacy">Privacy Policy</Link></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}