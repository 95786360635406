import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import axios from 'axios';
import { API_URL } from '../constants';

const reset = (event, email, newpwd, conpwd, history) => {
  event.preventDefault();
  if (newpwd.length === 0) {
    swal("Required fields");
  }else if(conpwd.length === 0){
    swal("Required Confirm Password");
  }else{
    axios({
      url: API_URL + '/sign.php',
      method: 'post',
      data: {
              newpwd,
              conpwd,
              resetemail: email,
          },
      headers: {'Access-Control-Allow-Origin' : '*'}
    }).then((res) => {
      if (res.data === 'reset'){
        swal('Reset Password Successful','', 'success');
        history.push("/login");
      }
    }).catch((err) => {})
  }
}

const forgotPass = (event, email, updateValid) => {
  event.preventDefault();
  if (email.length === 0){
    swal("Required fields");
  }else {
    axios({
      url: API_URL + '/sign.php',
      method: 'post',
      data: {
            forgotemail : email,
          },
      headers: {'Access-Control-Allow-Origin' : '*'}
    }).then(res => res.data).then((data) => {
      if (data === 'yes') {
        updateValid(true);
      }else{
        updateValid(false);
        swal(data,"", "error");
      }
    }).catch((err) => {});
  }
}

export default function PasswordForgot(props) {
  const [email, updateEmail] = useState('');
  const [valid, updateValid] = useState(false);
  const [newpwd, updateNewpwd] = useState('');
  const [conpwd, updateConpwd] = useState('');
 
  useEffect(() => {

    if(localStorage.getItem('email')==null ){
        
          document.getElementById('logout').style.display="none";
          document.getElementById('pdf').style.display="none";
          document.getElementById('updf').style.display="block";
  
          document.getElementById('color').style.display="none";
          document.getElementById('login').style.display="block";
          document.getElementById('signup').style.display="block";
          document.getElementById('home').style.display="block";
    }else{
         
          document.getElementById('color').style.display="block";
          document.getElementById('login').style.display="none";
          document.getElementById('signup').style.display="none";
          document.getElementById('logout').style.display="block";
          document.getElementById('pdf').style.display="block";
          document.getElementById('updf').style.display="none";
  
          document.getElementById('home').style.display="none";
        }
  });

  return(
            
    <div  style={{backgroundRepeat:'round',height:'70vh',marginTop:'15em'}}>   
        <div className="container">
            <div className="row">
               <div className="col-md-4"></div>
               {
                (valid === false) ? 
                <div className="col-md-4 box" style={{padding:'2%'}}>
                <h3 className="text-center" style={{color:'#25a498'}}>Forgot your password?</h3>
                <form id='frm' style={{marginTop:'2em'}}>
                
                <div className="form-group">
                  
                   <input type="text" onKeyUp={(e) => updateEmail(e.target.value)}  className="form-control" placeholder="Enter Email"></input>
                  
                </div>
                 <button type="submit" style={{padding:'12px 140px'}} onClick={(e) => forgotPass(e, email, updateValid)} class="button button-contactForm btn_1">Submit</button>
                 <div style={{textAlign:"center",marginTop:"1em"}}>Don't have an account?&nbsp;<Link  to="/signup">Create an account</Link></div>
                 </form>
                 <br/>
                
    
               </div>
               : 
               <div className="col-md-4 box" style={{padding:'2%'}}>
                <h3 className="text-center" style={{color:'#25a498'}}>Forgot your password?</h3>
                <form id='frm' style={{marginTop:'2em'}}>
                
                <div className="form-group">
                  <label>New Password</label>
                   <input type="password" onKeyUp={(e) => updateNewpwd(e.target.value)} id="newpwd" className="form-control" placeholder="Enter New Password"></input>
                   <label>Confirm Password</label>
                   <input type="password" onKeyUp={(e) => updateConpwd(e.target.value)} id="conpwd" className="form-control" placeholder="Enter Confirm Password"></input>
    
                </div>
                 <button type="submit" style={{padding:'12px 78px'}} onClick={(e) => reset(e, email, newpwd, conpwd, props.history)} class="button button-contactForm btn_1">Reset Password</button>
                 <div style={{textAlign:"center",marginTop:"1em"}}>Don't have an account?&nbsp;<Link  to="/signup">Create an account</Link></div>
                 </form>
                 <br/>
                
    
               </div>
               
               }
               
    
                <div className="col-md-1"></div>
            </div>     
          
        </div>
    </div>
              );
  
        }
  