import React from "react";

const TAU = Math.PI * 2;
class DraggablePieChart extends React.Component {
    defaults = {
        onchange: function () {},
        radius: 0.9,
        data: [],
        collapsing: false,
        minAngle: 0.1,
        drawSegment: this.drawSegment.bind(this),
        drawNode: this.drawNode.bind(this),
    };

    drawChart(setup) {
        setup = {
            ...{},
            ...this.defaults,
            ...setup
        };
        this.canvas = setup.canvas;
        this.context = setup.canvas.getContext("2d");

        if (!this.context) {
            console.log("Error: DraggablePieChart needs an html5 canvas.");
            return;
        }

        if (setup.proportions) {
            this.data = this.generateDataFromProportions(setup.proportions);
        } else if (setup.data) {
            this.data = setup.data;
        }

        this.draggedPie = null;
        this.hoveredIndex = -1;
        this.radius = setup.radius;
        this.collapsing = setup.collapsing;
        this.minAngle = setup.minAngle;
        this.drawSegment = setup.drawSegment;
        this.drawNode = setup.drawNode;
        this.onchange = setup.onchange;

        // Bind appropriate events
        if (this.is_touch_device()) {
            this.canvas.removeEventListener("touchstart", this.touchStart.bind(this));
            this.canvas.removeEventListener("touchmove", this.touchMove.bind(this));
            document.removeEventListener("touchend", this.touchEnd.bind(this));

            this.canvas.addEventListener("touchstart", this.touchStart.bind(this));
            this.canvas.addEventListener("touchmove", this.touchMove.bind(this));
            document.addEventListener("touchend", this.touchEnd.bind(this));
        } else {
            this.canvas.removeEventListener("mousedown", this.touchStart.bind(this));
            this.canvas.removeEventListener("mousemove", this.touchMove.bind(this));
            document.removeEventListener("mouseup", this.touchEnd.bind(this));

            this.canvas.addEventListener("mousedown", this.touchStart.bind(this));
            this.canvas.addEventListener("mousemove", this.touchMove.bind(this));
            document.addEventListener("mouseup", this.touchEnd.bind(this));
        }

        this.draw();
    }

    drawSegment(context, centerX, centerY, radius, startingAngle, arcSize, format, collapsed) {
        if (collapsed) {
            return;
        }
        // Draw coloured segment
        context.save();
        var endingAngle = startingAngle + arcSize;
        context.beginPath();
        context.moveTo(centerX, centerY);
        context.arc(centerX, centerY, radius, startingAngle, endingAngle, false);
        context.closePath();

        context.fillStyle = format.color;
        context.fill();
        context.restore();

        // Draw label on top
        context.save();
        context.translate(centerX, centerY);
        context.rotate(startingAngle);

        var fontSize = Math.floor(context.canvas.height / 20);
        var dx = centerX/2;
        var dy = centerY / 7;

        context.textAlign = "right";
        context.font = fontSize + "pt flaticon";
        context.fillText(format.label, dx, dy);
        context.restore();
    }

    drawNode(context, x, y, centerX, centerY, hover) {
        context.save();
        context.translate(centerX, centerY);
        context.fillStyle = "#000000";
        var rad = hover ? 8 : 7;
        context.beginPath();
        context.arc(x, y, rad, 0, TAU, true);
        context.fill();
        context.restore();
    }

    degreesToRadians(degrees) {
        return (degrees * Math.PI) / 180;
    }

    smallestSignedAngleBetween(target, source) {
        return Math.atan2(Math.sin(target - source), Math.cos(target - source));
    }

    mod(n, m) {
        return ((n % m) + m) % m;
    }

    is_touch_device() {
        return (
            "ontouchstart" in window || navigator.maxTouchPoints // works on most browsers
        ); // works on IE10/11 and Surface
    }

    normaliseAngle(angle) {
        return this.mod(angle + Math.PI, TAU) - Math.PI;
    }

    polarToCartesian(angle, radius) {
        return {
            x: radius * Math.cos(angle),
            y: radius * Math.sin(angle),
        };
    }

    touchStart(event) {
        this.draggedPie = this.getTarget(this.getMouseLocation(event));
        if (this.draggedPie) {
            this.hoveredIndex = this.draggedPie.index;
        }
    }

    touchEnd() {
        if (this.draggedPie) {
            this.draggedPie = null;
            this.draw();
        }
    }

    touchMove(event) {
        var dragLocation = this.getMouseLocation(event);
        if (!this.draggedPie) {
            var hoveredTarget = this.getTarget(dragLocation);
            if (hoveredTarget) {
                this.hoveredIndex = hoveredTarget.index;
                this.draw();
            } else if (this.hoveredIndex !== -1) {
                this.hoveredIndex = -1;
                this.draw();
            }
            return;
        }

        var draggedPie = this.draggedPie;

        var dx = dragLocation.x - draggedPie.centerX;
        var dy = dragLocation.y - draggedPie.centerY;

        // Get angle of grabbed target from centre of pie
        var newAngle = Math.atan2(dy, dx) - draggedPie.angleOffset;

        this.shiftSelectedAngle(newAngle);
        this.draw();
    }

    getMouseLocation(evt) {
        var rect = this.canvas.getBoundingClientRect();

        if (evt.clientX) {
            return {
                x: evt.clientX - rect.left,
                y: evt.clientY - rect.top,
            };
        } else {
            return {
                x: evt.targetTouches[0].clientX - rect.left,
                y: evt.targetTouches[0].clientY - rect.top,
            };
        }
    }

    /*
     * Generates angle data from proportions (array of objects with proportion, format
     */
    generateDataFromProportions(proportions) {
        // sum of proportions
        var total = proportions.reduce(function (a, v) {
            return a + v.proportion;
        }, 0);

        // begin at 0
        var currentAngle = 0;

        // use the proportions to reconstruct angles
        return proportions.map(function (v, i) {
            var arcSize = (TAU * v.proportion) / total;
            var data = {
                angle: currentAngle,
                format: v.format,
                collapsed: arcSize <= 0,
            };
            currentAngle = this.normaliseAngle(currentAngle + arcSize);
            return data;
        }, this);
    }

    /*
     * Move angle specified by index: i, by amount: angle in rads
     */
    moveAngle(i, amount) {
        if (this.data[i].collapsed && amount < 0) {
            this.setCollapsed(i, false);
            return;
        }

        var geometry = this.getGeometry();
        this.draggedPie = {
            index: i,
            angleOffset: 0,
            centerX: geometry.centerX,
            centerY: geometry.centerY,
            startingAngles: this.data.map(function (v) {
                return v.angle;
            }),
            collapsed: this.data.map(function (v) {
                return v.collapsed;
            }),
            angleDragDistance: 0,
        };

        this.shiftSelectedAngle(this.data[i].angle + amount);
        this.draggedPie = null;
        this.draw();
    }

    /*
     * Gets percentage of indexed slice
     */
    getSliceSizePercentage(index) {
        var visibleSegments = this.getVisibleSegments();

        for (var i = 0; i < visibleSegments.length; i += 1) {
            if (visibleSegments[i].index === index) {
                return (100 * visibleSegments[i].arcSize) / TAU;
            }
        }
        return 0;
    }

    /*
     * Gets all percentages for each slice
     */
    getAllSliceSizePercentages() {
        var visibleSegments = this.getVisibleSegments();
        var percentages = [];
        for (var i = 0; i < this.data.length; i += 1) {
            if (this.data[i].collapsed) {
                percentages[i] = 0;
            } else {
                for (var j = 0; j < visibleSegments.length; j += 1) {
                    if (visibleSegments[j].index === i) {
                        percentages[i] = (100 * visibleSegments[j].arcSize) / TAU;
                    }
                }
            }
        }

        return percentages;
    }

    /*
     * Gets the geometry of the pie chart in the canvas
     */
    getGeometry() {
        var centerX = Math.floor(this.canvas.width / 2);
        var centerY = Math.floor(this.canvas.height / 2);
        return {
            centerX: centerX,
            centerY: centerY,
            radius: Math.min(centerX, centerY) * this.radius,
        };
    }

    /*
     * Returns a segment to drag if given a close enough location
     */
    getTarget(targetLocation) {
        var geometry = this.getGeometry();
        var startingAngles = [];
        var collapsed = [];

        var closest = {
            index: -1,
            distance: 9999999,
            angle: null,
        };

        for (var i = 0; i < this.data.length; i += 1) {
            startingAngles.push(this.data[i].angle);
            collapsed.push(this.data[i].collapsed);

            if (this.data[i].collapsed) {
                continue;
            }

            var dx = targetLocation.x - geometry.centerX;
            var dy = targetLocation.y - geometry.centerY;
            var trueGrabbedAngle = Math.atan2(dy, dx);

            var distance = Math.abs(
                this.smallestSignedAngleBetween(trueGrabbedAngle, this.data[i].angle)
            );

            if (distance < closest.distance) {
                closest.index = i;
                closest.distance = distance;
                closest.angle = trueGrabbedAngle;
            }
        }

        if (closest.distance < 0.1) {
            return {
                index: closest.index,
                angleOffset: this.smallestSignedAngleBetween(
                    closest.angle,
                    startingAngles[closest.index]
                ),
                centerX: geometry.centerX,
                centerY: geometry.centerY,
                startingAngles: startingAngles,
                collapsed: collapsed,
                angleDragDistance: 0,
            };
        } else {
            return null;
        }
    }

    /*
     * Sets segments collapsed or uncollapsed
     */
    setCollapsed(index, collapsed) {
        // Flag to set position of previously collapsed to new location
        var setNewPos = this.data[index].collapsed && !collapsed;

        this.data[index].collapsed = collapsed;

        var visibleSegments = this.getVisibleSegments();

        // Shift other segments along to make space if necessary
        for (var i = 0; i < visibleSegments.length; i += 1) {
            // Start at this segment
            if (visibleSegments[i].index === index) {
                //Set new position
                if (setNewPos) {
                    var nextSegment =
                        visibleSegments[this.mod(i + 1, visibleSegments.length)];
                    this.data[index].angle = nextSegment.angle - this.minAngle;
                }

                for (var j = 0; j < visibleSegments.length - 1; j += 1) {
                    var currentSegment =
                        visibleSegments[this.mod(1 + i - j, visibleSegments.length)];
                    var nextAlongSegment =
                        visibleSegments[this.mod(i - j, visibleSegments.length)];

                    var angleBetween = Math.abs(
                        this.smallestSignedAngleBetween(
                            this.data[currentSegment.index].angle,
                            this.data[nextAlongSegment.index].angle
                        )
                    );

                    if (angleBetween < this.minAngle) {
                        this.data[nextAlongSegment.index].angle = this.normaliseAngle(
                            this.data[currentSegment.index].angle - this.minAngle
                        );
                    }
                }
                break;
            }
        }

        this.draw();
    }

    /*
     * Returns visible segments
     */
    getVisibleSegments() {
        // Collect data for visible segments
        var visibleSegments = [];
        for (var i = 0; i < this.data.length; i += 1) {
            if (!this.data[i].collapsed) {
                var startingAngle = this.data[i].angle;

                // Get arcSize
                var foundNextAngle = false;
                for (var j = 1; j < this.data.length; j += 1) {
                    var nextAngleIndex = (i + j) % this.data.length;
                    if (!this.data[nextAngleIndex].collapsed) {
                        var arcSize = this.data[nextAngleIndex].angle - startingAngle;
                        if (arcSize <= 0) {
                            arcSize += TAU;
                        }

                        visibleSegments.push({
                            arcSize: arcSize,
                            angle: startingAngle,
                            format: this.data[i].format,
                            index: i,
                        });

                        foundNextAngle = true;
                        break;
                    }
                }
                // Only one segment
                if (!foundNextAngle) {
                    visibleSegments.push({
                        arcSize: TAU,
                        angle: startingAngle,
                        format: this.data[i].format,
                        index: i,
                    });
                    break;
                }
            }
        }
        return visibleSegments;
    }

    /*
     * Returns invisible segments
     */
    getInvisibleSegments() {
        // Collect data for visible segments
        var invisibleSegments = [];
        for (var i = 0; i < this.data.length; i += 1) {
            if (this.data[i].collapsed) {
                invisibleSegments.push({
                    index: i,
                    format: this.data[i].format,
                });
            }
        }

        return invisibleSegments;
    }

    /*
     * Draws the pieChart
     */
    draw() {
        var context = this.context;
        var canvas = this.canvas;

        context.clearRect(0, 0, canvas.width, canvas.height);

        var geometry = this.getGeometry();

        var visibleSegments = this.getVisibleSegments();
        // Flags to get arc sizes and index of largest arc, for drawing order
        var largestArcSize = 0;
        var indexLargestArcSize = -1;

        // Get the largeset arcsize
        for (var i = 0; i < visibleSegments.length; i += 1) {
            if (visibleSegments[i].arcSize > largestArcSize) {
                largestArcSize = visibleSegments[i].arcSize;
                indexLargestArcSize = i;
            }
        }

        // Need to draw in correct order
        for (i = 0; i < visibleSegments.length; i += 1) {
            // Start with one *after* largest
            var index = this.mod(i + indexLargestArcSize + 1, visibleSegments.length);
            this.drawSegment(
                context,
                geometry.centerX,
                geometry.centerY,
                geometry.radius,
                visibleSegments[index].angle,
                visibleSegments[index].arcSize,
                visibleSegments[index].format,
                false
            );
        }

        // Now draw invisible segments
        var invisibleSegments = this.getInvisibleSegments();
        for (i = 0; i < invisibleSegments.length; i += 1) {
            this.drawSegment(
                context,
                geometry.centerX,
                geometry.centerY,
                geometry.radius,
                0,
                0,
                invisibleSegments[i].format,
                true
            );
        }

        // Finally draw drag nodes on top (order not important)
        for (i = 0; i < visibleSegments.length; i += 1) {
            var location = this.polarToCartesian(
                visibleSegments[i].angle,
                geometry.radius
            );
            this.drawNode(
                context,
                location.x,
                location.y,
                geometry.centerX,
                geometry.centerY,
                i === this.hoveredIndex
            );
        }

        this.onchange();
    }

    /*
     * *INTERNAL USE ONLY*
     * Moves the selected angle to a new angle
     */
    shiftSelectedAngle(newAngle) {
        if (!this.draggedPie) {
            return;
        }
        var draggedPie = this.draggedPie;

        // Get starting angle of the target
        var startingAngle = draggedPie.startingAngles[draggedPie.index];

        // Get diff from grabbed target start (as -pi to +pi)
        var angleDragDistance = this.smallestSignedAngleBetween(
            newAngle,
            startingAngle
        );

        // Get previous diff
        var previousDragDistance = draggedPie.angleDragDistance;

        // Determines whether we go clockwise or anticlockwise
        var rotationDirection = previousDragDistance > 0 ? 1 : -1;

        // Reverse the direction if we have done over 180 in either direction
        var sameDirection = (previousDragDistance > 0) === (angleDragDistance > 0);
        var greaterThanHalf =
            Math.abs(previousDragDistance - angleDragDistance) > Math.PI;

        if (greaterThanHalf && !sameDirection) {
            // Reverse the angle
            angleDragDistance =
                (TAU - Math.abs(angleDragDistance)) * rotationDirection;
        } else {
            rotationDirection = angleDragDistance > 0 ? 1 : -1;
        }

        draggedPie.angleDragDistance = angleDragDistance;

        // Set the new angle:
        this.data[draggedPie.index].angle = this.normaliseAngle(
            startingAngle + angleDragDistance
        );

        // Reset Collapse
        this.data[draggedPie.index].collapsed =
            draggedPie.collapsed[draggedPie.index];

        // Search other angles
        var shifting = true;
        var collapsed = false;
        var minAngle = this.minAngle;
        var numberOfAnglesShifted = 0;

        for (var i = 1; i < this.data.length; i += 1) {
            // Index to test each slice in order
            var index = this.mod(
                parseInt(draggedPie.index) + i * rotationDirection,
                this.data.length
            );

            // Get angle from target start to this angle
            var startingAngleToNonDragged = this.smallestSignedAngleBetween(
                draggedPie.startingAngles[index],
                startingAngle
            );

            // If angle is in the wrong direction then it should actually be OVER 180
            if (startingAngleToNonDragged * rotationDirection < 0) {
                startingAngleToNonDragged =
                    (startingAngleToNonDragged * rotationDirection + TAU) *
                    rotationDirection;
            }

            if (this.collapsing) {
                // *Collapsing behaviour* when smallest angle encountered

                // Reset collapse
                this.data[index].collapsed = draggedPie.collapsed[index];

                var checkForSnap = !collapsed && !this.data[index].collapsed;

                // Snap node to collapse, and prevent going any further
                if (
                    checkForSnap &&
                    startingAngleToNonDragged > 0 &&
                    angleDragDistance > startingAngleToNonDragged - minAngle
                ) {
                    this.data[draggedPie.index].angle = this.data[index].angle;
                    this.data[draggedPie.index].collapsed = true;
                    collapsed = true;
                } else if (
                    checkForSnap &&
                    startingAngleToNonDragged < 0 &&
                    angleDragDistance < startingAngleToNonDragged + minAngle
                ) {
                    this.data[draggedPie.index].angle = this.data[index].angle;
                    this.data[index].collapsed = true;
                    collapsed = true;
                } else {
                    this.data[index].angle = draggedPie.startingAngles[index];
                }
            } else {
                // *Shifting behaviour* when smallest angle encountered

                // Shift all other angles along
                var shift = (numberOfAnglesShifted + 1) * minAngle;

                if (
                    shifting &&
                    startingAngleToNonDragged > 0 &&
                    angleDragDistance > startingAngleToNonDragged - shift
                ) {
                    this.data[index].angle = this.normaliseAngle(
                        draggedPie.startingAngles[index] +
                        (angleDragDistance - startingAngleToNonDragged) +
                        shift
                    );
                    numberOfAnglesShifted += 1;
                } else if (
                    shifting &&
                    startingAngleToNonDragged < 0 &&
                    angleDragDistance < startingAngleToNonDragged + shift
                ) {
                    this.data[index].angle = this.normaliseAngle(
                        draggedPie.startingAngles[index] -
                        (startingAngleToNonDragged - angleDragDistance) -
                        shift
                    );
                    numberOfAnglesShifted += 1;
                } else {
                    shifting = false;
                    this.data[index].angle = draggedPie.startingAngles[index];
                }
            }
            //console.log(JSON.stringify(this.data));
        }
    }
}

export default DraggablePieChart;