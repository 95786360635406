import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Link
} from "react-router-dom";
import Footer from '../components/Footer';
import page1 from '../login';
import page2 from '../signup';
import feedback from '../feedback';
import resume from '../resume';
import resume2 from '../resume2';
import home from '../home';
import sampleresume from '../sampleresume';
import getstart from '../getstart';

import PasswordForgot from '../components/PasswordForgot';
import TermsofService from '../components/TermsofService';
import Privacy from '../components/Privacy';

export default function Routes(props) {
    return (
        <>
            <Router>

                <header className="main_menu home_menu ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg navbar-light">
                                    <a className="navbar-brand" href="/" style={{ width: '35%' }}><img src="img/logo.png" alt="logo" style={{ width: '50%' }} /></a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse main-menu-item justify-content-end" id="navbarSupportedContent" style={{ width: '75%' }}>
                                        <ul className="navbar-nav align-items-center">
                                            <li className="nav-item ">
                                                <a className="nav-link" id="home" activeClassName="active" href="/">Home</a>
                                            </li>
                                            <li className="nav-item ">
                                                <NavLink exact className="nav-link" id="sample" to="/sampleresume" refresh="true">Sample CV</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" id="login" to="/Getstart" refresh="true">Get Started</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" id='feedback' to="/feedback" refresh="true" refresh="true">Feedback</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" id="color" refresh="true" >Color</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" id="font" refresh="true">Font</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/login" id="updf" refresh="true">Export</Link>
                                                <Link className="nav-link" id="pdf" to={{ pathname: "/resume",action: "generatePDF" }} refresh="true">Export</Link>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="btn_1" id='logout' onClick={() => localStorage.removeItem("email")} to="/login" refresh="true">Logout</NavLink>
                                            </li>

                                            <li className="d-none d-lg-block">
                                                <NavLink className="btn_1" id="signup" to="/login" refresh="true">Login/Signup</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <Switch>
                    <Route exact path="/" component={home} refresh="true" />
                    <Route exact path="/index.html" component={home} refresh="true" />
                    <Route path="/home" component={home} refresh="true" />
                    <Route path="/login" component={page1} refresh="true" />
                    <Route path="/signup" component={page2} refresh="true" />
                    <Route path="/resume" component={resume} refresh="true" />
                    <Route  path="/resume2" component={resume2} refresh="true" />
                    <Route path="/Feedback" component={feedback} refresh="true" />
                    <Route path="/Sampleresume" component={sampleresume} refresh="true" />
                    <Route path="/Getstart" component={getstart} refresh="true" />
                    <Route path="/Termsofservice" component={TermsofService} refresh="true" />
                    <Route path="/Privacy" component={Privacy} refresh="true" />
                    <Route path="/password-forgot" component={PasswordForgot} refresh="true" />
                </Switch>
                <Footer></Footer>
            </Router>
        </>
    );
}