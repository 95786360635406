import  React,{useEffect} from 'react';
import send from './helpers/feedback';

export default function Feedback (props){

   useEffect(() => {
    if(localStorage.getItem('email')==null ){
      
      document.getElementById('logout').style.display="none";
      document.getElementById('pdf').style.display="none";
      document.getElementById('updf').style.display="none";

      document.getElementById('color').style.display="none";
      document.getElementById('login').style.display="block";
      document.getElementById('signup').style.display="block";
      document.getElementById('home').style.display="block";
      document.getElementById("feedback").style.display = "block";
      document.getElementById("sample").style.display = "block";
    }else{
    
      document.getElementById('color').style.display="block";
      document.getElementById('signup').style.display="none";
      document.getElementById('logout').style.display="block";
      document.getElementById('pdf').style.display="block";
      document.getElementById('updf').style.display="none";
      document.getElementById("feedback").style.display = "block";
      document.getElementById("sample").style.display = "block";
      document.getElementById('home').style.display="none";
    }
   });

return(
    <div>

      <section class="contact-section section_padding">
        <div class="container">

          <div class="row">
          <div class="col-12">
          <h2 class="contact-title"><center>Feedback Form</center></h2>
          </div>
          <div class="col-lg-12">
          <form class="form-contact contact_form"  method="post" id="contactForm" novalidate="novalidate">
          <div class="row">

          <div class="col-sm-6">
          <div class="form-group">
          <input class="form-control" name="name" id="name"  type="text" onfocus="if (!window.__cfRLUnblockHandlers) return false; placeholder = ''" onblur="if (!window.__cfRLUnblockHandlers) return false; placeholder = 'Enter your name'" placeholder="Enter your name" />
          </div>
          </div>

          <div class="col-sm-6">
          <div class="form-group">
          <input class="form-control" name="email" id="email"  type="email" onfocus="if (!window.__cfRLUnblockHandlers) return false; placeholder = ''" onblur="if (!window.__cfRLUnblockHandlers) return false; placeholder = 'Enter email address'" placeholder="Enter email address" />
          </div>
          </div>

          <div class="col-12">
          <div class="form-group">
          <textarea class="form-control w-100" name="message" id="message"  cols="30" rows="9" onfocus="if (!window.__cfRLUnblockHandlers) return false; placeholder = ''" onblur="if (!window.__cfRLUnblockHandlers) return false; placeholder = 'Enter Message'" placeholder="Enter Message" ></textarea>
          </div>
          <div class="form-group mt-3">
          <button type="button" onClick={send} class="button button-contactForm btn_1">Send Message</button>
          </div>
          </div>


          </div>
          </form>
          </div>
          </div>
        </div>
      </section>    
    </div>
        );
}